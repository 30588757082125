import {gql} from '@apollo/client';
import * as constants from '@/lib/utils/constants';
import {BlogInfoFragment, FooterFragment, GlobalFragment} from '@/lib/fragments';
import Container from '@/components/Container/Container';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import Main from '@/components/Main/Main';
import NavigationMenu from '@/components/NavigationMenu/NavigationMenu';
import PostCard from '@/components/PostCard/PostCard';
import PostsGrid from '@/components/PostsGrid/PostsGrid';
import SEO from '@/components/SEO/SEO';
import {getNextStaticProps} from '@faustwp/core';
import Button from "@/components/Button/Button";
import ImgSearch from "@/lib/assets/images/icon-search-w.svg";
import {useSearchOverlayStore} from "@/lib/stores/layout";
import Heading from "@/components/Heading/Heading";
import TermLinks from "@/components/TermLinks/TermLinks";

export default function Page(props) {
  const {data} = props;
  const toggleSearch = useSearchOverlayStore(state => state.toggle);

  const {
    generalSettings: {title: siteTitle, description: siteDescription} = {},
    momHomepagePosts: latestPosts,
    headlessSiteSettings: {siteSettings} = {},
    primaryMenuItems: {nodes: primaryMenu} = {},
    subMenuAItems: {nodes: subMenuA} = {},
    subMenuBItems: {nodes: subMenuB} = {},
    popularTags = [],
  } = data || {};

  return (
    <>
      <SEO noindex={true} title={`Page Not Found | ${siteTitle}`} description={siteDescription}/>

      <Header {...{primaryMenu, subMenuA, subMenuB, siteSettings, popularTags}}/>

      <Main>
        <Container className={'page404'}>
          <div className="content text-center">
            <h1>404: Not found</h1>
            <h2>This is somewhat embarrassing, isn’t it?</h2>
            <p>
              It looks like nothing was found at this location. Maybe try a
              <Button
                className={'searchButton'}
                variant={'primary'}
                onClick={toggleSearch}
              >
                Search <ImgSearch/>
              </Button>?
            </p>
          </div>

          <Heading level={'h2'}>The Latest News</Heading>
          <PostsGrid posts={latestPosts.slice(0, 4)}/>

          <style jsx global>{`
            .page404 h1 {
              margin-bottom: 40px;
            }

            .page404 .content {
              margin-bottom: 70px;
              margin-top: 70px;
            }

            .page404 button.searchButton {
              padding: 0 8px;
              margin-left: 5px;
              margin-right: 5px;
              height: 25px;
              line-height: 25px;
            }

            .page404 button.searchButton svg {
              width: 13px;
              height: 13px;
              vertical-align: -2px;
            }
          `}</style>
        </Container>
      </Main>
      <Footer {...{subMenuA, subMenuB, siteSettings}}/>
    </>
  );
}

Page.query = gql`
  ${BlogInfoFragment}
  ${FooterFragment}
  ${GlobalFragment}
  ${NavigationMenu.fragments.entry}
  ${PostCard.fragments.entry}
  ${TermLinks.fragments.entryRootTag}
  query GetPageData(
    $headerLocation: MenuLocationEnum
    $subMenuALocation: MenuLocationEnum
    $subMenuBLocation: MenuLocationEnum
  ) {
    generalSettings {
      ...BlogInfoFragment
    }
    headlessSiteSettings {
      ...GlobalFragment
      ...FooterFragment
    }
    momHomepagePosts(first: 4) {
      ...${PostCard.fragments.key}
    }
    primaryMenuItems: menuItems(where: { location: $headerLocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    subMenuAItems: menuItems(where: { location: $subMenuALocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    subMenuBItems: menuItems(where: { location: $subMenuBLocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    popularTags: tags(first: 20, where: {orderby: COUNT, order: DESC}) {
      ...${TermLinks.fragments.keyRootTag}
    }
  }
`;

Page.variables = () => {
  return {
    headerLocation: constants.MENU_PRIMARY_LOCATION,
    subMenuALocation: constants.MENU_FOOTER_LEFT_LOCATION,
    subMenuBLocation: constants.MENU_FOOTER_RIGHT_LOCATION,
  };
};

export function getStaticProps(ctx) {
  return getNextStaticProps(ctx, {Page, props: {title: 'Page Not Found'}});
}
